import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, styled } from '@mui/material'
import { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-cycle
import { LayoutQuery } from '../Layout'
import { SubHeaderTabs } from './SubHeaderTabs'

interface StyledTabsProps {
  children?: React.ReactNode
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))(({ theme }) => ({
  color: '#000000',
  minHeight: 56,
  height: 56,
  fontWeight: 'bold',
  marginRight: '40px',
  overflow: 'hidden',
  fontSize: 20,
  [theme.breakpoints.up('md')]: {
    minHeight: 56,
    height: 56,
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: 'none',
  },
}))

export interface StyledTabProps {
  label: string
  id: string
  value: string
  'aria-controls': string
  onHover?: () => void
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} onMouseEnter={props.onHover} />
))(({ theme }) => ({
  minWidth: 0,
  minHeight: 56,
  height: 56,
  paddingRight: 0,
  paddingLeft: 0,
  marginRight: '40px',
  textAlign: 'center',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: 20,
  color: '#000000',
  [theme.breakpoints.up('md')]: {
    minHeight: 56,
    height: 56,
    fontSize: 20,
  },
  '&.Mui-selected': {
    color: '#983FF1',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#983FF1',
  },
}))

export interface StyledTabPanelProps {
  value: string
  children?: React.ReactNode
}

export const StyledTabPanel = styled((props: StyledTabPanelProps) => <TabPanel {...props} />)(
  ({ theme }) => ({
    backgroundColor: '#F9F9F9',
    boxShadow: '5px 5px 7px 3px rgba(0, 0, 0, 0.25)',
    position: 'absolute',
    width: '80%',
    zIndex: 10,
    left: 0,
    top: 56,
    padding: 0,
    maxWidth: '1054px',
    '&.MuiTabPanel-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '@media(min-width: 1536px)': {
      left: `calc((100vw - 1536px)/2)`,
    },
  }),
)

export function HeaderTabs(props: Pick<LayoutQuery, 'menu'>) {
  const { menu } = props
  const [showMenu, setShowMenu] = useState(false)
  const firstLevelParentNodes = menu?.items
  const firstLevelNodes =
    firstLevelParentNodes && firstLevelParentNodes[0] && firstLevelParentNodes[0]?.children
      ? firstLevelParentNodes[0]?.children.filter((node) => node?.include_in_menu)
      : []

  const [value, setValue] = useState('')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  useEffect(() => {
    if (showMenu) {
      setValue(value)
    } else {
      setValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu])

  return (
    <TabContext value={value}>
      <Box className='flex items-center justify-start bg-none text-black lg:w-full'>
        <div
          className='flex w-full items-center justify-start'
          onMouseEnter={() => setShowMenu(true)}
          onMouseLeave={() => setShowMenu(false)}
        >
          <StyledTabs value={value} onChange={handleChange}>
            {firstLevelNodes &&
              firstLevelNodes?.length > 0 &&
              firstLevelNodes.map((item, index) => (
                <StyledTab
                  label={item?.name ?? ''}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  {...a11yProps(index)}
                  value={index.toString()}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  onHover={() => setValue(index.toString())}
                  sx={{
                    paddingLeft: index === 0 ? '60px' : 0,
                  }}
                />
              ))}
          </StyledTabs>
        </div>
      </Box>
      {firstLevelNodes &&
        firstLevelNodes.map((item, index) => (
          <div
            className={`${showMenu ? 'block' : 'hidden'} max-w-[1054px]`}
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
          >
            <StyledTabPanel value={index.toString()}>
              <SubHeaderTabs
                menuItems={item?.children?.filter((node) => node?.include_in_menu)}
                url={item?.url_path ?? ''}
                setShowMenu={setShowMenu}
              />
            </StyledTabPanel>
          </div>
        ))}
    </TabContext>
  )
}
