import { LayoutDefaultProps } from '@graphcommerce/next-ui'
import { GlobalProvider } from '../NextUi/GlobalProvider'
import { Footer } from './Footer'
import { Header } from './Header'
import { LayoutQuery } from './Layout.gql'
import { LayoutDefault } from './LayoutDefault'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutNavigation(props: LayoutNavigationProps) {
  const { footer, header, menu, children, ...uiProps } = props

  return (
    <GlobalProvider>
      <LayoutDefault
        {...uiProps}
        header={<Header menu={menu} header={header} />}
        footer={<Footer footer={footer} />}
      >
        {children}
      </LayoutDefault>
    </GlobalProvider>
  )
}
