import {
  useCustomerQuery,
  useCustomerSession,
  UseCustomerSessionReturn,
} from '@graphcommerce/magento-customer/hooks'
import { Trans } from '@lingui/react'
import { NoSsr, SwipeableDrawer } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { CustomerDocument } from '../../../graphql/Customer.gql'
import { AccountSignInUpForm } from '../../CustomerAccount/AccountSignInUpForm/AccountSignInUpForm'
import { globalContext } from '../../NextUi/globalContext'
import { AccountMenu } from './AccountMenu'

type CustomerFabContentProps = {
  session?: UseCustomerSessionReturn
}

function CustomerFabContent(props: CustomerFabContentProps) {
  const { session } = props
  const { drawer, setDrawer } = useContext(globalContext)
  const ref = useRef<HTMLButtonElement>(null)
  const dashboard = useCustomerQuery(CustomerDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const customer = dashboard.data?.customer
  const customerInitials = `${customer?.firstname?.trimStart().charAt(0)}${customer?.lastname
    ?.trimStart()
    .charAt(0)}`

  const customerName = customer ? `${customer?.firstname} ${customer?.lastname}` : ''
  const [showMenu, setShowMenu] = useState<boolean>(false)

  useEffect(() => {
    if (ref.current) {
      ref?.current?.blur()
    }
  }, [session?.loggedIn])

  if (session?.loggedIn) {
    return (
      <div className='relative'>
        <button type='button' onClick={() => setShowMenu((previous) => !previous)} ref={ref}>
          <div className='relative flex h-[35px] w-[35px] items-center justify-center rounded-[50%] bg-medium-gray'>
            {customer && customerInitials && (
              <span className='Type-XL-Medium text-pure-white'>{customerInitials}</span>
            )}
          </div>
        </button>
        <AccountMenu
          accountName={customerName}
          accountEmail={customer?.email}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
      </div>
    )
  }

  return (
    <div className='relative'>
      <button type='button' onClick={() => setDrawer({ id: drawer.id === 'auth' ? null : 'auth' })}>
        <div className='flex h-[35px] w-full items-center justify-center whitespace-nowrap rounded-[20px] border-[1px] border-new-templi-purple bg-dark-gray px-5'>
          <p className='Type-Large-Medium whitespace-nowrap text-pure-white'>
            <Trans id='Log in' />
          </p>
        </div>
      </button>

      <SwipeableDrawer
        anchor='right'
        onClose={() => setDrawer({ id: null })}
        onOpen={() => {}}
        open={drawer.id === 'auth'}
        classes={{
          paper: 'shadow-m max-w-[520px]',
        }}
      >
        <AccountSignInUpForm onClose={() => setDrawer({ id: null })} />
      </SwipeableDrawer>
    </div>
  )
}

export type CustomerFabProps = Omit<CustomerFabContentProps, 'session'>

export function CustomerFab(props: CustomerFabProps) {
  const session = useCustomerSession()

  return (
    <NoSsr fallback={<CustomerFabContent {...props} />}>
      <CustomerFabContent session={session} {...props} />
    </NoSsr>
  )
}
